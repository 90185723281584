.cell {
  background-color: #eee;
  padding: 7px;

  &.own {
    background-color: #fff;

    &.name {
      background-color: #eee;
    }
  }

  &.name {
    font-weight: bold;
    background-color: #ddd;
  }
}

.title {
  font-weight: bold;
  position: absolute;
  z-index: 5;
}
