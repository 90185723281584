.transactionTableContainer {
  padding-bottom: 30px;
}
.showMoreButton {
  outline: none;
  background: none;
  border: none;
  padding: 5px;
  cursor: pointer;
  color: dodgerblue;
  margin-bottom: 15px;
}
.selectBlock {
  margin-left: 20px;
}

.downloadButton {
  @extend .selectBlock;
  width: 100px;
}

.logoImg {
  width: 50px !important;
  margin-right: 20px;
}

.titleContainer {
  padding-top: 10px;
  padding-bottom: 10px;
}

.pdfExportContainer {
  position: absolute;
  transform: translate(-1000px, 0);
  opacity: 0;
  z-index: -1;
}

.transactions_data_content_button {
  background: none;
  outline: none;
  cursor: pointer;
  border: none;
}

.wordWrap {
  white-space: pre-wrap !important;
  text-align: center;
}

.wordWrapCell {
  white-space: pre-wrap;
}

.methodCell {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  border-radius: 3px;
  background: #000;
  //text-shadow: 0 1px 0 rgb(0 0 0 / 10%);
  font-family: sans-serif;
  color: #fff;
  width: 75px;
  height: 25px;
}

.POST {
  background: #49cc90;
}
.GET {
  background: #61affe;
}
.PUT {
  background: #fca130;
}
.DELETE {
  background: #f93e3e;
}

.transactionsDataContentButton {
  background: none;
  outline: none;
  cursor: pointer;
  border: none;
  color: blue;
  text-overflow: ellipsis;
  overflow: hidden;
  display: contents;
}
