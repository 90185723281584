.wordWrapCell {
  white-space: pre-wrap;
}

.checkBoxContainer {
  margin-right: 5px;
}

.fontStyleBadge {
  font-size: 14px;
  font-weight: normal;
  mix-blend-mode: difference;
}

.updateSection {
  display: flex;
  height: 40px;
}

.updateButton {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-right: -1px;
}

.selectBox {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.updateIconFetching {
  animation: show 1s infinite linear;
  @keyframes show {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
