.transactionTableContainer {
  padding-bottom: 30px;
  margin-right: 0;
  margin-left: 0;
}
.showMoreButton {
  outline: none;
  background: none;
  border: none;
  padding: 5px;
  cursor: pointer;
  color: dodgerblue;
  margin-bottom: 15px;
}
.selectBlock {
  margin-left: 20px;
}

.downloadButton {
  @extend .selectBlock;
  width: 100px;
  //margin-top: 10px;
}

.logoImg {
  width: 50px !important;
  margin-right: 20px;
}

.titleContainer {
  padding-top: 10px;
  padding-bottom: 10px;
}

.pdfExportContainer {
  position: absolute;
  transform: translate(-1000px, 0);
  opacity: 0;
  z-index: -1;
}

.transactions_data_content_button {
  background: none;
  outline: none;
  cursor: pointer;
  border: none;
}

.wordWrapCell {
  white-space: pre-wrap;
}
