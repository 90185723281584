.contentWrapper {
  width: 100%;
  overflow: auto;
}
.table {
  width: 2000px;
}

.selectBlock {
  margin-left: 20px;
}

.downloadButton {
  @extend .selectBlock;
  width: 100px;
}
