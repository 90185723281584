:global {
  .dx-box-item:nth-child(3) .dx-box-item-content {
    .dx-box-item:nth-child(1) .dx-datagrid-edit-form-item {
      padding-right: 0;
    }

    .dx-box-item:nth-child(2) {
      flex: 0 !important;
    }
  }

  .dx-filemanager-general-toolbar {
    .dx-toolbar-before .dx-toolbar-button:nth-child(2) {
      display: none;
    }

    .dx-toolbar-after {
      display: none;
    }
  }

  .dx-filemanager
    .dx-filemanager-container
    .dx-drawer-panel-content.dx-drawer-panel-content-initial {
    display: none;
  }

  .dx-filemanager .dx-filemanager-breadcrumbs {
    display: none;
  }

  .dx-filemanager-details
    .dx-filemanager-file-actions-button.dx-filemanager-file-actions-button-activated,
  .dx-filemanager-file-actions-button {
    visibility: visible;
  }
}
