.budgeWrapper {
  width: 100%;
  margin: 0 auto;
}

.budge {
  width: 20px;
  height: 20px;
  border-radius: 4px;
}

.tableWrapper {
  height: min-content;
}
