.updateSection {
  display: flex;
  height: 40px;
}

.updateIconFetching {
  animation: show 1s infinite linear;
  @keyframes show {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
