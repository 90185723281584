.loginPage {
  font-family: Helvetica Neue;
  color: #373a3c;
  background-image: url('./loginBg.png');
  background-size: cover;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cardStyles {
  border-radius: 0 !important;
  width: 400px;
}

.logoImg {
  width: 75px !important;
  height: auto;
  margin-right: 15px;
}

.logoWrapper {
  display: flex;
  align-items: center;
}

blockquote {
  border-right: 5px solid #eceeef;
}

.formTitle {
  font-weight: 400;
}

.submitButton {
  padding: 8px 54px !important;
  width: 100%;
}

.keyImage {
  width: 20px;
  height: 20px;
}

.keyButton {
  display: block;
  margin: 10px 0 0 auto;
}
.headerTab {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-around;
  margin: 0;
  text-align: center;
  margin-bottom: 1rem;
}

.errorMessage {
  margin-bottom: 10px;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 0 10px 0;
}

.webAuthButton {
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.authIconsContainer {
  display: flex;
  align-items: center;
}

.googleButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  outline: none;
  border: none;
  margin-right: 10px;
}
