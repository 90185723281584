.codeEditorWrapper {
  position: relative;
  box-sizing: content-box;
}

.changeSizeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #d50000;
  height: 10px;
  width: 10px;
}

.test_form_container {
  margin-bottom: 30px;
}
.test_form {
  display: flex;
  flex-direction: column;
}
.test_form_input_item {
  flex: 1;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.test_form_input_label {
  flex: 0.15;
  margin-bottom: 0;
  font-weight: 500;
}
.res_label {
  color: royalblue;
}
.test_form_input {
  flex: 0.85;
  margin: 0;
  padding: 7px 9px 8px;
  background: rgba(0, 0, 0, 0);
  color: #333;
  font-size: 1em;
  border-radius: 4px;
  min-height: 34px;
  border: 1px solid #ddd;
  border-radius: 4px;
  outline: none;
}
.res_input {
  color: #ed143d;
}

.test_form_input:hover {
  border-color: rgba(51, 122, 183, 0.4);
  transition: 0.3s;
}

.test_form_input:focus {
  border: 1px solid #337ab7;
  transition: 0.3s;
}

.test_form_buttons_container {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.test_form_button {
  background-color: #fff;
  border-color: #ddd;
  color: #333;
  outline: none;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  line-height: 2;
  margin-right: 10px;
  width: 150px;
  min-height: 36px;
}

.test_form_button:last-child {
  margin-right: 0;
}

.test_form_button:hover {
  background-color: #f5f5f5;
  border-color: #ddd;
  outline: none;
  transition: 0.3s;
}

.test_form_button:active {
  background-color: #c2c2c2;
  border-color: #ddd;
  color: #333;
  outline: none;
  transition: 0.3s;
}

.test_form_button:focus {
  outline: none;
}

.test_form_button[disabled] {
  background-color: #ddd;
  cursor: not-allowed;
}
