.logo,
.logo:hover {
  color: #373a3c;
  text-decoration: none;
}

.logoImg {
  width: 50px !important;
  margin-right: 20px;
}

.logoText,
.logoText:hover {
  line-height: 1;
}

.logOut,
.logOut:hover {
  color: #373a3c !important;
}

:global(.dropdown-menu) .sub {
  position: relative;

  .subDropdown {
    transform: translate(95%, -70%);
  }

  &:hover .subDropdown {
    display: block;
  }
}

:global(.dx-toolbar-text-auto-hide .dx-button .dx-icon) {
  color: #2ecc71;
}

:global(.dx-datagrid .dx-link.dx-link-add) {
  color: #2ecc71;
}
:global(.dx-datagrid .dx-link.dx-link-edit) {
  color: #f1c40f;
}

:global(.dx-datagrid .dx-link-delete) {
  color: #e74c3c;
}

:global(.dx-treelist .dx-link.dx-link-edit) {
  color: #f1c40f;
}

:global(.dx-treelist .dx-link.dx-link-add) {
  color: #2ecc71;
}

:global(.dx-treelist .dx-link.dx-link-delete) {
  color: #e74c3c;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 40px;
  padding-bottom: 40px;
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.updateSection {
  display: flex;
  height: 40px;
}

.updateButton {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-right: -1px;
}

.selectBox {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.updateIconFetching {
  animation: show 1s infinite linear;
  @keyframes show {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
