.container {
  display: block;
  height: 100%;
  overflow-y: scroll;
  padding: 20px;
}

.scroll {
  padding: 0;
}
