.selectBlock {
  margin-left: 20px;
}

.downloadButton {
  @extend .selectBlock;
  width: 100px;
  margin-top: 10px;
}

.logoImg {
  width: 50px !important;
  margin-right: 20px;
}

.titleContainer {
  padding-top: 10px;
  padding-bottom: 10px;
}

.pdfExportContainer {
  position: absolute;
  transform: translate(-1000px, 0);
  opacity: 0;
}
