.badgeContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.badgeSpan {
  margin: 0 4px;
}

.collapseButton {
  margin: 10px 0;
  width: 140px;
}

.badgeButton {
  line-height: 1rem;
}

.blink {
  animation: blinking 1s 2;
}

@keyframes blinking {
  0%{
    background-color: #fff;
  }
  50%{
    background-color: rgba(53, 121, 246, 0.26);
  }
  100%{
    background-color: #fff;
  }
}
