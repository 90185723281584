.selectBlock {
  margin-left: 20px;
}

.downloadButton {
  @extend .selectBlock;
  width: 100px;
  margin-top: 10px;
}

.downloadButtonWide {
  @extend .selectBlock;
  width: 120px;
  margin-top: 10px;
}

.logoImg {
  width: 50px !important;
  margin-right: 20px;
}

.titleContainer {
  padding-top: 10px;
  padding-bottom: 10px;
}

.pdfExportContainer {
  position: absolute;
  opacity: 0;
  left: -10000px;
  top: -10000px;
}

.collapseButton {
  margin: 10px 0;
  width: 140px;
}
