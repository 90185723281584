.updateSection {
  display: flex;
  height: 40px;
}

.updateButton {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-right: -1px;
}

.selectBox {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.order0 {
  order: 0;
}

.order1 {
  order: 1;
}

.order2 {
  order: 2;
}

.updateIconFetching {
  animation: show 1s infinite linear;
  @keyframes show {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
