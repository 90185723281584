.logo,
.logo:hover {
  color: #373a3c;
  text-decoration: none;
}

.logoImg {
  width: 50px !important;
  margin-right: 20px;
}

.logoText,
.logoText:hover {
  line-height: 1;
}

.logOut,
.logOut:hover {
  color: #373a3c !important;
}

:global(.dropdown-menu) .sub {
  position: relative;

  .subDropdown {
    transform: translate(95%, -70%);
  }

  &:hover .subDropdown {
    display: block;
  }
}

:global(.dx-toolbar-text-auto-hide .dx-button .dx-icon) {
  color: #2ecc71;
}

:global(.dx-datagrid .dx-link.dx-link-add) {
  color: #2ecc71;
}
:global(.dx-datagrid .dx-link.dx-link-edit) {
  color: #f1c40f;
}

:global(.dx-datagrid .dx-link-delete) {
  color: #e74c3c;
}

:global(.dx-treelist .dx-link.dx-link-edit) {
  color: #f1c40f;
}

:global(.dx-treelist .dx-link.dx-link-add) {
  color: #2ecc71;
}

:global(.dx-treelist .dx-link.dx-link-delete) {
  color: #e74c3c;
}

.profileRoundButton {
  background: none;
  outline: none !important;
  cursor: pointer;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: #2ecc71;
  overflow: hidden;
  &:focus {
    box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.3);
  }
}

.gravatarClass {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

.userData {
  display: flex;
  justify-content: flex-start;
  margin: 0 10px;
}

.nameSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.userDropdownMenu {
  width: 250px;
}

.gravatarClassHeader {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.headerName {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 175px;
}

.email {
  font-size: 9pt;
  color: #b3bac5;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 175px;
}

.success {
  color: #2ecc71 !important;
}

.error {
  color: #e74c3c !important;
}

.passShowBtn {
  outline: none !important;
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.passwordInput {
  border-right: none;
}

.showPassBtnContainer {
  background: none;
  border-left: none;
}
