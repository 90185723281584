.collapseButton {
  margin: 10px 0;
  width: 140px;
}

.checkBox {
  margin-right: 15px;
}

.autoUpdateText {
  margin-right: 5px;
}

.nameStyle {
  color: dodgerblue;
  cursor: pointer;
  transition: 0.3s;
  white-space: pre-line;
  &:hover {
    text-decoration: underline;
    filter: brightness(0.8);
  }
}

.cellStyle {
  vertical-align: middle !important;
}

.updateSection {
  display: flex;
  height: 40px;
}

.updateButton {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-right: -1px;
}

.selectBox {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.updateIconFetching {
  animation: show 1s infinite linear;
  @keyframes show {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
